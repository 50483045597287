import { Box, Carousel, MediaBox } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { StyledArrowImage } from "areas/landing/elements/components"
import SliderItem from "areas/landing/elements/slider-item"
import Slider from "dependencies/carousel"
import useWindowDimensions from "helpers/window-dimensions"

const SliderWrapper = ({ children, sliderRef, sliderType, ...props }) => {
  const slickSlider = (
    <Carousel displayArrows="none" sliderRef={sliderRef}>
      {children}
    </Carousel>
  )

  if (sliderType === "slick") {
    return slickSlider
  }

  return (
    <>
      <MediaBox lessThan="tablet">{slickSlider}</MediaBox>
      <MediaBox greaterThanOrEqual="tablet">
        <Slider {...props} innerRef={sliderRef}>
          {children}
        </Slider>
      </MediaBox>
    </>
  )
}

SliderWrapper.propTypes = {
  children: PropTypes.node,
  sliderRef: PropTypes.object,
  sliderType: PropTypes.string,
}

// Desktop slider will always be a slick slider
// If slider_type = "native" - mobile slider will be native scroll
const LandingSlider = ({ element, lazyload, sliderRef, slides }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const { isMobile, isTablet } = useWindowDimensions()

  const { top: arrowTop, nextPlacement, prevPlacement, ...arrowStyle } =
    element.arrow_style || {}

  // Determine number of slides to show
  let sliderSettings = {
    slidesToShow: 1,
  }
  if (element.item_width && typeof element.item_width.tablet === "number") {
    sliderSettings = {
      slidesToShow: element.item_width.tablet,
    }
    if (typeof element.item_width.desktop === "number") {
      sliderSettings = {
        slidesToShow: isMobile
          ? 1
          : isTablet
          ? element.item_width.tablet
          : element.item_width.desktop,
      }
    }
  }
  if (element.item_style) {
    sliderSettings = {
      slidesToShow: element.item_style.slides_to_show,
    }
  }

  sliderSettings = {
    defaultControlsConfig: {
      nextButtonText: (
        <StyledArrowImage
          {...arrowStyle}
          alt="Next"
          src={
            arrowStyle?.arrowIcon
              ? require(`images/icons/${arrowStyle.arrowIcon}`)
              : require("images/icons/arrow_bg_light.svg")
          }
        />
      ),
      prevButtonText: (
        <StyledArrowImage
          {...arrowStyle}
          alt="Previous"
          src={
            arrowStyle?.arrowIcon
              ? require(`images/icons/${arrowStyle.arrowIcon}`)
              : require("images/icons/arrow_bg_light.svg")
          }
        />
      ),
    },
    dots: false,
    ...sliderSettings,
    ...element.slider_settings,
    frameAriaLabel: element?.name || element.slider_settings?.frameAriaLabel,
  }

  useEffect(() => {
    if (sliderRef?.current && !sliderRef.current?.slickGoTo) {
      // eslint-disable-next-line no-unused-vars
      sliderRef.current.slickGoTo = (index, ...args) => {
        setSlideIndex(index)
      }
    }
  }, [sliderRef?.current?.slickGoTo, setSlideIndex])

  const { containWide, ...sliderBoxStyle } =
    element.style?.slider_box_style || {}

  return (
    <Box
      contain={!containWide}
      containWide={containWide}
      {...element.style?.container_style}
      {...element.tabPanelProps}
    >
      <Box {...sliderBoxStyle}>
        <SliderWrapper
          arrowTop={arrowTop}
          beforeSlide={(_, index) => setSlideIndex(index)}
          nextPlacement={nextPlacement}
          prevPlacement={prevPlacement}
          slideIndex={slideIndex}
          sliderType={element.slider_type}
          {...sliderSettings}
          sliderRef={sliderRef}
        >
          {slides.map((item, index) => (
            <SliderItem
              element={element}
              index={index}
              item={item}
              key={`slide${index}`}
              lazyload={
                lazyload || (typeof lazyload === "undefined" && index > 3)
              }
            />
          ))}
        </SliderWrapper>
      </Box>
    </Box>
  )
}

LandingSlider.propTypes = {
  element: PropTypes.object,
  lazyload: PropTypes.bool,
  sliderRef: PropTypes.object,
  slides: PropTypes.array,
}

export default LandingSlider
