import { Box, MediaBox } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { Suspense, useLayoutEffect, useRef, useState } from "react"
import styled from "styled-components"

import { StyledArrowImage } from "areas/landing/elements/components"
import Slider from "dependencies/carousel"
import useWindowDimensions from "helpers/window-dimensions"

import FeaturedSlide from "./featured-slide"

const FlexBox = styled(Box)`
  width: 100%;
  ${(props) => props.theme.mediaQueries.tablet`
    ${
      props.originalHeight &&
      props.originalWidth &&
      `flex: ${props.originalWidth / props.originalHeight};`
    }
    ${props.clientWidth && `width: ${props.clientWidth};`}
  `}
`

const SliderWrapper = styled(Slider)`
  .slider-control-centerright {
    margin-right: 5px;
  }
  .slider-control-centerleft {
    margin-left: 5px;
  }

  ${(props) => props.theme.mediaQueries.desktop`
    .slider-container {
      margin: -8px;
    }
  `}
`

const FeaturedSlider = ({ element, lazyload, slides }) => {
  const [featuredWidth, setFeaturedWidth] = useState("")
  const [sliderWidth, setSliderWidth] = useState("")
  const containerRef = useRef()

  const { isMobile } = useWindowDimensions()
  const sliderSettings = {
    defaultControlsConfig: {
      nextButtonText: (
        <MediaBox greaterThanOrEqual="desktop">
          <StyledArrowImage
            {...element.arrow_style}
            alt="Next"
            src={
              element.arrow_style?.arrowIcon
                ? require(`images/icons/${element.arrow_style.arrowIcon}`)
                : require("images/icons/arrow_bg_light.svg")
            }
          />
        </MediaBox>
      ),
      prevButtonText: (
        <MediaBox greaterThanOrEqual="desktop">
          <StyledArrowImage
            {...element.arrow_style}
            alt="Previous"
            src={
              element.arrow_style?.arrowIcon
                ? require(`images/icons/${element.arrow_style.arrowIcon}`)
                : require("images/icons/arrow_bg_light.svg")
            }
          />
        </MediaBox>
      ),
    },
    frameAriaLabel: element?.name || element.sliderSettings?.frameAriaLabel,
    ...element.slider_settings,
  }

  if (typeof window !== "undefined") {
    useLayoutEffect(() => {
      const resizeListener = () => {
        window.requestAnimationFrame(() => {
          if (
            containerRef?.current &&
            element.featured_width &&
            element.slider_width
          ) {
            const containerWidth = containerRef.current.offsetWidth - 24 // Minus 24px gap
            const totalWidth = element.featured_width + element.slider_width
            const featuredRatio = element.featured_width / totalWidth
            const sliderRatio = element.slider_width / totalWidth

            setFeaturedWidth(`${containerWidth * featuredRatio}px`)
            setSliderWidth(`${containerWidth * sliderRatio}px`)
          }
        })
      }
      resizeListener()
      window.addEventListener("resize", resizeListener)
      return () => window.removeEventListener("resize", resizeListener)
    }, [])
  }

  const featuredItem = slides.find((item) => item.featured)

  return (
    <Box {...element.slider_wrapper_style}>
      <Box
        display={{ tablet: "flex" }}
        ref={containerRef}
        width="100%"
        {...element.slider_container_style}
      >
        {!isMobile && featuredItem && (
          <FlexBox
            clientWidth={featuredWidth}
            originalHeight={element.slider_height}
            originalWidth={element.featured_width}
          >
            <FeaturedSlide element={element} lazyload slide={featuredItem} />
          </FlexBox>
        )}
        <FlexBox
          clientWidth={sliderWidth}
          originalHeight={element.slider_height}
          originalWidth={element.slider_width}
        >
          {typeof window !== "undefined" && (
            <Suspense fallback={<div />}>
              <SliderWrapper
                arrowTop={element.arrow_style?.top}
                {...sliderSettings}
              >
                {slides
                  .filter((slide) => isMobile || !slide.featured)
                  .sort((a, b) => (a.order || 0) - (b.order || 0))
                  .map((slide, index) => (
                    <FeaturedSlide
                      element={element}
                      index={index}
                      isSlideItem
                      key={`slide${index}`}
                      lazyload={isMobile && (lazyload || index > 0)}
                      slide={slide}
                    />
                  ))}
              </SliderWrapper>
            </Suspense>
          )}
        </FlexBox>
      </Box>
    </Box>
  )
}

FeaturedSlider.propTypes = {
  element: PropTypes.object,
  lazyload: PropTypes.bool,
  slides: PropTypes.array,
}

export default FeaturedSlider
