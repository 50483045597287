import { Box, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const ItemPrice = styled.div`
  color: ${(props) => props.theme.colors.grey[65]};
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  margin-bottom: 2px;
  padding: 0 7px;
  text-align: center;
`

const OriginalPrice = styled.span`
  display: inline;
  font-weight: normal;
  text-decoration: line-through;

  @media (min-width: 568px) {
    display: inline;
  }
`

const SalePrice = styled.span`
  color: #c20101 !important;
`

const DealBlock = styled(Box)`
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  margin-top: 3px;
  padding: 1px 8px;
`

const toCurrency = (price) => {
  const percision = 0
  return `${sl.config.default_currency}${parseFloat(price).toFixed(percision)}`
}

const PriceField = ({ product, selectedProductLook }) => {
  let deal
  let original_price = product.price.retail_price
  let selling_price = product.price.reg_price

  if (
    product &&
    product.looks &&
    product.default_look &&
    product.looks[product.default_look]
  ) {
    original_price = product.looks[product.default_look]["retail"]
    selling_price = product.looks[product.default_look]["selling"]
  }

  if (selectedProductLook?.retail && selectedProductLook?.selling) {
    original_price = selectedProductLook.retail
    selling_price = selectedProductLook.selling
  }

  const deal_amount = Math.ceil(
    ((original_price - selling_price) * 100) / original_price
  )

  if (product.addons.has_sale_price) {
    deal = (
      <DealBlock bg="sale" color="white">
        {`MEGA DEAL -${deal_amount}%`}
      </DealBlock>
    )
  }

  return (
    <>
      <ItemPrice>
        {"from ("}
        <OriginalPrice>{toCurrency(original_price)}</OriginalPrice>
        {") "}
        <SalePrice>{toCurrency(selling_price)}</SalePrice>
      </ItemPrice>
      {product.hasDiamond && (
        <Typography as="em" color="black" display="block" fontSize="12px" light>
          {product.hasDiamond}
        </Typography>
      )}
      {false && deal}
    </>
  )
}

PriceField.propTypes = {
  product: PropTypes.object,
  selectedProductLook: PropTypes.object,
}

export default PriceField
