import { Box, Picture } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import Textarea from "components/landing/textarea"

const Container = styled(Box).attrs((props) => ({
  flex: "1",
  ...props,
}))``

const PositionBlock = styled(Box).attrs((props) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "space-between",
  position: "absolute",
  px: 1,
  py: 3,
  top: "0",
  width: "100%",
  ...props,
}))``

const FeaturedItem = ({ item, lazyload, linkClickData }) => {
  return (
    <Container {...item.container_style}>
      <Box position="relative">
        {item.image && (
          <Picture
            alt={item.image.alt}
            fallback={item.image.fallback}
            lazyload={lazyload}
            srcSet={item.image.srcSet}
          />
        )}
        <PositionBlock {...item.style}>
          {item.top_textarea && (
            <Textarea
              element={item.top_textarea}
              lazyload={lazyload}
              linkClickData={linkClickData}
              style={{
                textAlign: "center",
                ...item.top_textarea.style,
              }}
            />
          )}
          {item.bottom_textarea && (
            <Textarea
              element={item.bottom_textarea}
              lazyload={lazyload}
              linkClickData={linkClickData}
              style={{
                textAlign: "center",
                ...item.bottom_textarea.style,
              }}
            />
          )}
        </PositionBlock>
      </Box>
    </Container>
  )
}

FeaturedItem.propTypes = {
  element: PropTypes.object,
  isSlideItem: PropTypes.bool,
  item: PropTypes.object,
  lazyload: PropTypes.bool,
  linkClickData: PropTypes.string,
}

export default FeaturedItem
