import { Box, Image, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import queryString from "query-string"
import React from "react"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"

import { Badge } from "areas/collection2/products/collection-product"
import Textarea from "components/landing/textarea"
import { logCurrentPageInCollectionStats } from "helpers/collection"

import PriceField from "./price-field"

const ProductLink = styled(Typography).attrs({
  as: Link,
})`
  display: block;
  margin-bottom: 20px;
  text-align: center;
  ${(props) =>
    props.align === "vertical" &&
    `
    width: ${100 / props.mobile_column}%;
  `};

  @media (min-width: 768px) {
    margin-bottom: 40px;
    width: auto;
    ${(props) =>
      props.align === "vertical" &&
      `
      width: ${100 / props.desktop_column}%;
    `};
  }
`

const ImageContainer = styled(Box).attrs((props) => ({
  mx: "auto",
  position: "relative",
  textAlign: "center",
  ...props,
}))``

const ProductImage = styled(Image)`
  width: 100%;
`

const LandingProduct = ({
  align,
  desktop_column,
  element,
  imageContainerStyle,
  index,
  lazyload,
  location,
  mobile_column,
  product,
  product_defaults,
  show_prices,
}) => {
  let product_look_query = {}
  let img_look_url = `${sl.config.full_main_render_url}${product.style_code}?view=t4`
  let img_link_url = `/products/${product.style_code}/${product.permalink}`

  if (typeof product_defaults === "object") {
    for (const key of Object.keys(product_defaults || {})) {
      product_look_query[key] = encodeURIComponent(product_defaults[key])
    }
  } else {
    // import from spreadsheet is in array
    for (const key of Object.keys(product_defaults[index] || {})) {
      product_look_query[key] = encodeURIComponent(product_defaults[index][key])
    }
  }

  img_look_url = queryString.stringifyUrl({
    query: product_look_query,
    url: img_look_url,
  })
  img_link_url = queryString.stringifyUrl({
    query: { ...product_look_query, itag: product.internal_link_tag },
    url: img_link_url,
  })

  if (
    product &&
    product.looks &&
    product.default_look &&
    product.looks[product.default_look]
  ) {
    img_link_url = product.looks[product.default_look]["product_url"]
  }

  const badge = product.badges && product.badges[0]

  return (
    <ProductLink
      align={align}
      data-lc={element?.anchor_name || element?.id}
      desktop_column={desktop_column || 3}
      key={`prec${index}`}
      mobile_column={mobile_column || 2}
      onClick={() => logCurrentPageInCollectionStats(location.pathname)}
      to={img_link_url}
    >
      <ImageContainer {...imageContainerStyle}>
        {badge && (
          <Badge bg={badge.color} color={badge.label_color} left="0">
            {badge.label}
          </Badge>
        )}
        {lazyload ? (
          <ProductImage
            alt={product.name}
            className="lazyload"
            data-src={`${img_look_url}&dim=256`}
            data-srcset={`
                ${img_look_url}&dim=256 256w,
                ${img_look_url}&dim=512 512w
              `}
            sizes="(max-width: 375px) 65vw, (max-width: 767px) 60vw, (min-width: 768px) 245px"
            src={require("images/icons/jewlr.gif")}
          />
        ) : (
          <ProductImage
            alt={product.name}
            sizes="(max-width: 375px) 65vw, (max-width: 767px) 60vw, (min-width: 768px) 245px"
            src={`${img_look_url}&dim=256`}
            srcSet={`
                ${img_look_url}&dim=256 256w,
                ${img_look_url}&dim=512 512w
              `}
          />
        )}
        {show_prices && <PriceField product={product} />}
        {product.textarea && (
          <Textarea
            element={product.textarea}
            linkClickData={element?.anchor_name || element?.id}
            style={product.textarea.style}
          />
        )}
      </ImageContainer>
    </ProductLink>
  )
}

LandingProduct.propTypes = {
  align: PropTypes.string,
  desktop_column: PropTypes.number,
  element: PropTypes.object,
  imageContainerStyle: PropTypes.object,
  index: PropTypes.number.isRequired,
  lazyload: PropTypes.bool,
  location: PropTypes.object,
  mobile_column: PropTypes.number,
  product: PropTypes.object.isRequired,
  product_defaults: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  show_prices: PropTypes.bool.isRequired,
}

LandingProduct.defaultProps = {
  show_prices: false,
}

export default withRouter(LandingProduct)
