import { Box } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"

import LooksSelector from "areas/collection2/looks-selector"
import { Badge } from "areas/collection2/products/collection-product"
import ProductImage from "areas/collection2/products/product-image"
import Textarea from "components/landing/textarea"
import { logCurrentPageInCollectionStats } from "helpers/collection"

import PriceField from "./price-field"

const ProductWrapper = styled(Box).attrs((props) => ({
  mb: { _: "20px", tablet: "40px" },
  ...props,
}))`
  text-align: center;
  ${(props) =>
    props.align === "vertical" &&
    `
    width: ${100 / props.mobile_column}%;
  `};

  @media (min-width: 768px) {
    width: auto;
    ${(props) =>
      props.align === "vertical" &&
      `
      width: ${100 / props.desktop_column}%;
    `};
  }
`

const ImageContainer = styled(Box).attrs((props) => ({
  mx: "auto",
  position: "relative",
  textAlign: "center",
  ...props,
}))``

const LooksSelectorWrapper = styled(Box).attrs((props) => ({
  height: "33px",
  ...props.theme.collectionLooksWrapper,
}))``

const LandingProduct = ({
  align,
  desktop_column,
  hide_metal_selector,
  imageContainerStyle,
  index,
  location,
  mobile_column,
  product,
  productWrapperStyle,
  show_prices,
  element,
}) => {
  const [selectedLook, setSelectedLook] = useState(setDefaultLook(product))
  const [firstImageLoaded, setFirstImageLoaded] = useState(true)

  const selectedProductLook = product.looks ? product.looks[selectedLook] : null

  if (!selectedProductLook) {
    // Prevents selectedLook from being undefined and breaking the component on reload of products. Gives useEffect a chance to update.
    return null
  }

  const badge = product.badges && product.badges[0]

  useEffect(() => {
    // Update selected metal when default_look is changed
    setSelectedLook(setDefaultLook(product))
  }, [product])

  return (
    <ProductWrapper
      align={align}
      desktop_column={desktop_column || 3}
      key={`prec${index}`}
      mobile_column={mobile_column || 2}
      onClick={() => logCurrentPageInCollectionStats(location.pathname)}
      {...productWrapperStyle}
    >
      <ImageContainer {...imageContainerStyle}>
        <Box
          as={Link}
          data-lc={element?.anchor_name || element?.id}
          display="block"
          to={selectedProductLook.product_url}
        >
          {badge && (
            <Badge bg={badge.color} color={badge.label_color} left="0">
              {badge.label}
            </Badge>
          )}
          <ProductImage
            alt={product.name}
            firstImageLoaded={firstImageLoaded}
            images={selectedProductLook.images}
            inLanding
            index={index}
            setFirstImageLoaded={(value) => setFirstImageLoaded(value)}
            styleCode={product.style_code}
            type={product.product_type}
          />
        </Box>

        {!hide_metal_selector && (
          <LooksSelectorWrapper>
            {Object.keys(product.looks).length > 1 && (
              <LooksSelector
                looks={product.looks}
                product={product}
                selectedLook={selectedLook}
                updateLook={(look) => setSelectedLook(look)}
              />
            )}
          </LooksSelectorWrapper>
        )}

        <Box
          as={Link}
          data-lc={element?.anchor_name || element?.id}
          display="block"
          to={selectedProductLook.product_url}
        >
          {show_prices && (
            <PriceField
              product={product}
              selectedProductLook={selectedProductLook}
            />
          )}
          {product.textarea && (
            <Textarea
              element={product.textarea}
              linkClickData={element?.anchor_name || element?.id}
              style={product.textarea.style}
            />
          )}
        </Box>
      </ImageContainer>
    </ProductWrapper>
  )
}

LandingProduct.propTypes = {
  align: PropTypes.string,
  desktop_column: PropTypes.number,
  element: PropTypes.object,
  hide_metal_selector: PropTypes.bool,
  imageContainerStyle: PropTypes.object,
  index: PropTypes.number.isRequired,
  location: PropTypes.object,
  mobile_column: PropTypes.number,
  product: PropTypes.object.isRequired,
  productWrapperStyle: PropTypes.object,
  show_prices: PropTypes.bool.isRequired,
}

const setDefaultLook = (product) => {
  if (!product.looks) return ""
  return Object.prototype.hasOwnProperty.call(
    product.looks,
    product.default_look
  )
    ? product.default_look
    : Object.keys(product.looks)[0]
}

LandingProduct.defaultProps = {
  hide_metal_selector: false,
  show_prices: false,
}

export default withRouter(LandingProduct)
