import { Flex } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import FeaturedItem from "./featured-item"

const ItemBox = styled(Flex).attrs((props) => ({
  width: "100%",
  ...props,
}))``

const StyledLink = styled(Link)`
  display: block;
  ${(props) =>
    props.$isSlideItem &&
    props.theme.mediaQueries.desktop`
      margin: 8px;
  `}
`

const FeaturedSlide = ({ element, isSlideItem, lazyload, slide }) => {
  return (
    <StyledLink
      $isSlideItem={isSlideItem}
      aria-label={slide.items.length > 0 ? slide.items[0]?.title : ""}
      data-lc={element?.anchor_name || element?.id}
      to={slide.url}
    >
      <ItemBox {...slide.style}>
        {slide.items.map((item, index) => (
          <FeaturedItem
            element={element}
            item={item}
            key={`${element?.key}-${item.key}-item-${index}`}
            lazyload={lazyload}
            linkClickData={element.anchor_name || element.id}
          />
        ))}
      </ItemBox>
    </StyledLink>
  )
}

FeaturedSlide.propTypes = {
  element: PropTypes.object,
  isSlideItem: PropTypes.bool,
  lazyload: PropTypes.bool,
  slide: PropTypes.object,
}

export default FeaturedSlide
