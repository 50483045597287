import { Box, Image, Picture, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"

import StyledLink from "components/landing/link"
import Textarea from "components/landing/textarea"

import LandingProduct from "./landing-product/landing-product"
import NewLandingProduct from "./landing-product/new-landing-product"
import LandingSuperProduct from "./landing-super-product"

const SliderItem = ({ element, index, item, lazyload }) => {
  if (item.type === "super_product") {
    return (
      <LandingSuperProduct
        element={element}
        lazyload={lazyload}
        product={item}
        show_prices={element.show_prices}
      />
    )
  } else if (item.type === "product") {
    return item.product_defaults ? (
      <LandingProduct
        element={element}
        imageContainerStyle={{ ...item.style }}
        index={index}
        lazyload={lazyload}
        product={item}
        product_defaults={item.product_defaults}
        show_prices={element.show_prices}
      />
    ) : (
      <NewLandingProduct
        element={element}
        imageContainerStyle={{ ...item.style }}
        index={index}
        product={item}
        show_prices={element.show_prices}
      />
    )
  } else if (item.type?.includes("image")) {
    let image = item.image ? (
      <StyledLink
        aria-label={item?.title?.text}
        data-cy={`landing-slider-img-desktop-${index}`}
        display="block"
        linkClickData={element.anchor_name || element.id}
        type="image"
        url={item.url}
      >
        <Image
          alt={item.alt || item.image.alt || item?.title?.text}
          lazyload={lazyload}
          mx="auto"
          src={item.image}
          width="100%"
          {...item.image}
        />
      </StyledLink>
    ) : (
      <StyledLink
        aria-label={item?.title?.text}
        display="block"
        linkClickData={element.anchor_name || element.id}
        type="image"
        url={item.link}
      >
        <Image
          alt={item?.title?.text}
          lazyload={lazyload}
          mx="auto"
          src={`${sl.config.jewlr_asset_url}landing/${item.folder}/image.jpg`}
          width="100%"
        />
      </StyledLink>
    )
    if (item.type === "image_responsive") {
      image = (
        <StyledLink
          aria-label={item?.title?.text}
          display="block"
          linkClickData={element.anchor_name || element.id}
          type="image"
          url={item.link}
        >
          <Box width="100%">
            <Picture
              {...item.image}
              alt={item.image?.alt || item?.title?.text}
              lazyload={lazyload}
            />
          </Box>
        </StyledLink>
      )
    }
    return (
      <Box pb={1} pt="4px">
        <Box {...item.style}>
          {image}
          {item.title && (
            <Typography
              as="h3"
              fontSize={{ _: "20px", tablet: "22px" }}
              fontWeight="light"
              mb="10px"
              mt={2}
              {...item.title.style}
            >
              {item.title.text}
            </Typography>
          )}
          {item.cta && (
            <StyledLink
              display="block"
              fontWeight="300"
              linkClickData={element.anchor_name || element.id}
              mb={1}
              type="underline"
              url={item.link}
              {...item.cta.style}
            >
              {item.cta.text}
            </StyledLink>
          )}
          {item.textarea && (
            <Textarea
              element={item.textarea}
              linkClickData={element.anchor_name || element.id}
              style={item.textarea.style}
            />
          )}
        </Box>
      </Box>
    )
  }
  return ""
}

SliderItem.propTypes = {
  element: PropTypes.object,
  index: PropTypes.number,
  item: PropTypes.object,
  lazyload: PropTypes.bool,
}

export default SliderItem
