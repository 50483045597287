import { Box, Button, Image, MediaBox } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const ArrowWrapper = styled(MediaBox)`
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  ${(props) =>
    props.prev &&
    `
    left: ${props.xPlacement || props.prevPlacement || "0"}px;
  `}
  ${(props) =>
    props.next &&
    `
    right: ${props.xPlacement - 16 || props.nextPlacement || "-16"}px;
  `}
`
const ArrowImage = styled(Box).attrs((props) => ({
  as: "img",
  height: props.height || "30px",
  top: props.top || "50%",
  width: props.width || { tablet: "30px" },
}))`
  margin: 0 auto;
  transform: translateY(-50%) ${(props) => props.next && " scaleX(-1)"};
`

export const Arrow = (props) => {
  return (
    <ArrowWrapper
      className={props.className}
      greaterThanOrEqual="tablet"
      next={props.next}
      nextPlacement={props.nextPlacement}
      prev={props.prev}
      prevPlacement={props.prevPlacement}
      xPlacement={props.xPlacement}
    >
      <Button
        aria-label={props.prev ? "Previous" : "Next"}
        display="block"
        height="100%"
        onClick={props.onClick}
        p={{ desktop: 0, tablet: 0 }}
        position="relative"
        top="0px"
        width={props.width}
      >
        <ArrowImage
          alt={props.prev ? "Previous" : "Next"}
          className="lazyload"
          data-src={
            props.arrowIcon
              ? require(`images/icons/${props.arrowIcon}`)
              : require("images/icons/arrow_bg_light.svg")
          }
          height={props.height}
          next={props.next}
          prev={props.prev}
          src={require("images/icons/jewlr.gif")}
          top={props.top}
          width={props.width}
        />
      </Button>
    </ArrowWrapper>
  )
}

Arrow.propTypes = {
  arrowIcon: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  next: PropTypes.bool,
  nextPlacement: PropTypes.string,
  onClick: PropTypes.func,
  prev: PropTypes.bool,
  prevPlacement: PropTypes.string,
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  userAgent: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  xPlacement: PropTypes.number,
}

export const StyledArrowImage = styled(Image).attrs((props) => ({
  height: props.height || "30px",
  mx: "auto",
  my: 0,
  ratio: "1 / 1",
  width: props.width,
  ...props,
}))``
