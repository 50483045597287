import { Box, Image, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import queryString from "query-string"
import React from "react"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"

import { Badge } from "areas/collection2/products/collection-product"
import { logCurrentPageInCollectionStats } from "helpers/collection"
import { to_currency } from "helpers/number"

const Container = styled(Box).attrs((props) => ({
  pl: 2,
  pr: { _: 0, tablet: 2 },
  textAlign: "center",
  width: { _: "70vw", tablet: "100%" },
  ...props,
}))``

const ImageContainer = styled(Box).attrs((props) => ({
  mb: 3,
  mx: "auto",
  position: "relative",
  textAlign: "center",
  ...props,
}))``

const ProductImage = styled(Image)`
  width: 100%;
`

const ItemText = styled(Typography).attrs((props) => ({
  fontSize: "18px",
  fontWeight: "300",
  height: "40px",
  lineHeight: "20px",
  mb: 1,
  ...props,
}))``

const Cta = styled(Box).attrs((props) => ({
  fontSize: "16px",
  fontWeight: "500",
  ...props,
}))`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

const LandingSuperProduct = ({
  element,
  lazyload,
  location,
  product,
  show_prices,
}) => {
  let product_look_query = {}
  let img_look_url = `${sl.config.full_main_render_url}${product.style_code}?view=t4`
  let img_link_url = `/products/${product.style_code}/${product.permalink}`

  const { product_defaults } = product
  if (typeof product_defaults === "object") {
    for (const key of Object.keys(product_defaults || {})) {
      product_look_query[key] = encodeURIComponent(product_defaults[key])
    }
  }

  img_look_url = queryString.stringifyUrl({
    query: product_look_query,
    url: img_look_url,
  })
  img_link_url = queryString.stringifyUrl({
    query: { ...product_look_query, itag: product.internal_link_tag },
    url: img_link_url,
  })

  if (
    product &&
    product.looks &&
    product.default_look &&
    product.looks[product.default_look]
  ) {
    img_link_url = product.looks[product.default_look]["product_url"]
  }

  const badge = product.badges && product.badges[0]

  const default_look =
    product.looks && product.default_look && product.looks[product.default_look]
  const original_price = default_look?.retail ?? product.price.retail_price
  const price_to_display =
    default_look?.selling ?? product.price.price_to_display

  const { cta_style, title_style, ...item_style } =
    element.style?.item_style || {}

  return (
    <Box>
      <Container {...item_style}>
        <Box
          as={Link}
          data-lc={element?.anchor_name || element?.id}
          display="block"
          onClick={() => logCurrentPageInCollectionStats(location.pathname)}
          to={img_link_url}
        >
          <ImageContainer>
            {badge && (
              <Badge bg={badge.color} color={badge.label_color} left="0">
                {badge.label}
              </Badge>
            )}
            {lazyload ? (
              <ProductImage
                alt={product.name}
                className="lazyload"
                data-src={`${img_look_url}&dim=256`}
                data-srcset={`
                ${img_look_url}&dim=256 256w,
                ${img_look_url}&dim=512 512w
              `}
                sizes="(max-width: 375px) 65vw, (max-width: 767px) 60vw, (min-width: 768px) 245px"
                src={require("images/icons/jewlr.gif")}
              />
            ) : (
              <ProductImage
                alt={product.name}
                sizes="(max-width: 375px) 65vw, (max-width: 767px) 60vw, (min-width: 768px) 245px"
                src={`${img_look_url}&dim=256`}
                srcSet={`
                ${img_look_url}&dim=256 256w,
                ${img_look_url}&dim=512 512w
              `}
              />
            )}
          </ImageContainer>
        </Box>
        <ItemText as="h3" {...title_style}>
          {product.title || product.name}
        </ItemText>
        {show_prices && (
          <ItemText>
            From {to_currency(price_to_display)} (
            <span style={{ textDecoration: "line-through" }}>
              {to_currency(original_price)}
            </span>
            )
          </ItemText>
        )}
        <Cta {...cta_style}>
          <Link
            data-lc={element?.anchor_name || element?.id}
            onClick={() => logCurrentPageInCollectionStats(location.pathname)}
            to={img_link_url}
          >
            Shop Now
          </Link>
        </Cta>
      </Container>
    </Box>
  )
}

LandingSuperProduct.propTypes = {
  element: PropTypes.object,
  lazyload: PropTypes.bool,
  location: PropTypes.object,
  product: PropTypes.object,
  show_prices: PropTypes.bool,
}

export default withRouter(LandingSuperProduct)
