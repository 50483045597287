import PropTypes from "prop-types"
import React from "react"

import FeaturedSlider from "./slider/featured-slider"
import NativeSlickSlider from "./slider/native-slick-slider"
import OverflowSlider from "./slider/overflow-slider"

const LandingSlider = ({ element, lazyload, sliderRef }) => {
  // Only render first 5 items on initial DOM load, then load the rest after
  const slides = element.items.filter(
    (item) =>
      !item.countries ||
      item.countries?.includes(sl.config.site_country.toUpperCase()) ||
      item.countries?.length < 1
  )

  if (["native", "slick"].includes(element.slider_type)) {
    return (
      <NativeSlickSlider
        element={element}
        lazyload={lazyload}
        sliderRef={sliderRef}
        slides={slides}
      />
    )
  } else if (element.slider_type === "overflow_slick") {
    return (
      <OverflowSlider element={element} lazyload={lazyload} slides={slides} />
    )
  } else if (element.slider_type === "featured") {
    return (
      <FeaturedSlider element={element} lazyload={lazyload} slides={slides} />
    )
  }
}

LandingSlider.propTypes = {
  element: PropTypes.object,
  lazyload: PropTypes.bool,
  sliderRef: PropTypes.object,
}

export default LandingSlider
