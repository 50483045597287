import { Box, MediaBox } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import CollectionProduct from "areas/collection2/products/collection-product"
import SliderNavigation from "components/slider/slider-navigation"
import Slider from "dependencies/carousel"

const Container = styled(Box).attrs((props) => ({
  ml: {
    desktop: `max(16px, (100% - ${props.theme.container.desktop} + 2em)/2)`,
    tablet: `max(16px, (100% - ${props.theme.container.tablet} + 2em)/2)`,
    widescreen: `max(16px, (100% - ${props.theme.container.widescreen} + 2em)/2)`,
  },
  pl: { _: 2, tablet: 0 },
  ...props,
}))``

const SliderContainer = styled(Box).attrs((props) => ({
  mb: 4,
  ...props,
}))``

const Item = styled.div`
  padding-right: 16px;
  width: 65vw;
  ${(props) => props.theme.mediaQueries.tablet`
    padding-right: 32px;
    width: 35vw;
  `}
  ${(props) => props.theme.mediaQueries.desktop`
    width: 28vw;
  `}
  @media (min-width: 1500px) {
    width: 24vw;
  }
  @media (min-width: 2000px) {
    max-width: 420px;
    width: 18vw;
  }
`

const SliderWrapper = styled(Slider)`
  .slider-list {
    width: fit-content;
  }

  .slider-control-bottomleft {
    bottom: -44px;
    position: absolute;
  }
`

const BottomLeftControls = ({ nextSlide, previousSlide, slideCount }) => {
  return (
    <MediaBox {...(slideCount < 4 && { lessThan: "desktop" })}>
      <SliderNavigation
        onNext={nextSlide}
        onPrev={previousSlide}
        variant="hollow"
      />
    </MediaBox>
  )
}

BottomLeftControls.propTypes = {
  nextDisabled: PropTypes.bool,
  nextSlide: PropTypes.func,
  previousDisabled: PropTypes.bool,
  previousSlide: PropTypes.func,
  slideCount: PropTypes.number,
}

const OverflowSlider = ({ element, slides }) => {
  const sliderSettings = {
    arrows: false,
    dots: false,
    renderBottomLeftControls: BottomLeftControls,
    slideWidth: "auto",
    slidesToShow: "auto",
    wrapAround: element.infinite,
    ...element.sliderSettings,
  }

  return (
    <Container {...element.style}>
      <SliderContainer {...element.sliderStyle}>
        <SliderWrapper {...sliderSettings}>
          {slides.map((item, index) => (
            <div key={`slide${index}${item.name || ""}`}>
              <Item>
                <CollectionProduct
                  containerStyle={{
                    inCollection: false,
                    mb: 0,
                    padding: 0,
                    width: "100%",
                  }}
                  index={index}
                  product={item}
                />
              </Item>
            </div>
          ))}
        </SliderWrapper>
      </SliderContainer>
    </Container>
  )
}

OverflowSlider.propTypes = {
  element: PropTypes.object,
  slides: PropTypes.array,
}

export default OverflowSlider
