import { faArrowLeft as lightArrowLeft } from "@jewlr/pro-light-svg-icons/faArrowLeft"
import { faArrowRight as lightArrowRight } from "@jewlr/pro-light-svg-icons/faArrowRight"
import { Box, FontAwesomeIcon } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const ArrowWrapper = styled(Box).attrs((props) => {
  let styling = {}
  if (props.variant === "hollow") {
    styling = {
      bg: "transparent",
      border: "1px solid",
      borderColor: "grey.10",
      color: "grey.65",
    }
  } else if (props.variant === "solid") {
    styling = {
      bg: "white",
      border: "1px solid",
      borderColor: "grey.10",
      color: "grey.65",
    }
  }
  return { as: "button", type: "button", ...styling, ...props }
})`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: 20px;
  justify-content: center;
  height: 56px;
  border-radius: 50%;
  width: 56px;
  &:active {
  }
  ${(props) =>
    props.variant === "invert" &&
    `
      background: transparent;
      border: 1px solid white;
      color: white;
      &:hover {
        background: white;
        color: black;
      }
  `}
`

const SliderNavigation = ({ onNext, onPrev, variant }) => (
  <>
    <ArrowWrapper
      aria-label="Next"
      mr={4}
      onClick={() => onPrev()}
      variant={variant}
    >
      <FontAwesomeIcon icon={lightArrowLeft} />
    </ArrowWrapper>
    <ArrowWrapper
      aria-label="Previous"
      onClick={() => onNext()}
      variant={variant}
    >
      <FontAwesomeIcon icon={lightArrowRight} />
    </ArrowWrapper>
  </>
)

SliderNavigation.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  variant: PropTypes.oneOf(["hollow", "solid", "invert"]),
}

export default SliderNavigation
